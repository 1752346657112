/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../Components/fonts/BebasNeue.eot) format("eot"), url(../../../../Components/fonts/BebasNeue.otf) format("otf"), url(../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../Components/fonts/BebasNeue_Regular.eot) format("eot"), url(../../../../Components/fonts/BebasNeue_Regular.otf) format("otf"), url(../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../Components/fonts/Lato-Black.eot) format("eot"), url(../../../../Components/fonts/Lato-Black.otf) format("otf"), url(../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../Components/fonts/Lato-Regular.eot) format("eot"), url(../../../../Components/fonts/Lato-Regular.otf) format("otf"), url(../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../Components/fonts/GOTHIC.eot) format("eot"), url(../../../../Components/fonts/GOTHIC.otf) format("otf"), url(../../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-examenes-preventivos {
  margin-top: 100px;
  margin-bottom: 100px;
}

body #seccion-examenes-preventivos .container {
  position: relative;
}

body #seccion-examenes-preventivos .container h4 {
  font-family: "Lato-Black";
  padding-bottom: 20px;
}

body #seccion-examenes-preventivos .container span {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  margin-top: 15px;
}

body #seccion-examenes-preventivos .container span img {
  position: relative;
  z-index: 1;
  margin: 0px;
}

body #seccion-examenes-preventivos .container span div {
  position: absolute;
  height: 100%;
  z-index: 2;
  margin: 0px;
  left: 0px;
  top: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

body #seccion-examenes-preventivos .container span div div {
  position: relative;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  margin: 0px;
  left: 0px;
  top: 0px;
  transition: ease-in-out 0.5s;
}

body #seccion-examenes-preventivos .container span div h3 {
  top: 0px;
  left: 0px;
  padding-top: 25%;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  width: 100%;
  text-align: center !important;
  position: absolute;
  z-index: 3;
  color: #FFFFFF;
  margin: 0px;
  font-family: "Lato-Black";
}

body #seccion-examenes-preventivos .container span:hover div div,
body #seccion-examenes-preventivos .container span:active div div,
body #seccion-examenes-preventivos .container span:focus div div {
  background-color: rgba(0, 0, 0, 0.3);
  transition: ease-in-out 0.5s;
}

@media only screen and (max-width: 500px) {
  body #seccion-examenes-preventivos {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  body #seccion-examenes-preventivos .container {
    position: relative;
  }
  body #seccion-examenes-preventivos .container h4 {
    font-family: "Lato-Black";
    padding-bottom: 20px;
  }
  body #seccion-examenes-preventivos .container span {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin-top: 15px;
  }
  body #seccion-examenes-preventivos .container span img {
    position: relative;
    z-index: 1;
    margin: 0px;
  }
  body #seccion-examenes-preventivos .container span div {
    position: absolute;
    height: 100%;
    z-index: 2;
    margin: 0px;
    left: 0px;
    top: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
  body #seccion-examenes-preventivos .container span div div {
    position: relative;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    margin: 0px;
    left: 0px;
    top: 0px;
  }
  body #seccion-examenes-preventivos .container span div h3 {
    left: 0px;
    padding-top: 25%;
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: middle;
    width: 100%;
    text-align: center !important;
    position: absolute;
    z-index: 3;
    color: #FFFFFF;
    margin: 0px;
    font-family: "Lato-Black";
  }
}
