/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #NavbarVeterinaria {
  border: none;
  background-color: #2984be;
  border-radius: 0px 0px 0px 0px;
  height: 100px;
  -webkit-box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
  -moz-box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
  box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
  /* ANIMATED X */
  /* ANIMATED X COLLAPSED */
  /* END ANIMATED X */
}

body #NavbarVeterinaria #brand {
  position: relative;
  height: 75px;
  top: 12px;
  left: 10vw;
}

body #NavbarVeterinaria .navbar-header {
  width: 100%;
  padding: 10px !important;
  height: 100% !important;
}

body #NavbarVeterinaria .navbar-header .navbar-toggle {
  background-color: #FFFFFF;
  border-color: transparent;
  margin-top: 20px;
}

body #NavbarVeterinaria .navbar-header .navbar-toggle:hover, body #NavbarVeterinaria .navbar-header .navbar-toggle:active, body #NavbarVeterinaria .navbar-header .navbar-toggle:focus {
  background-color: #2984be;
  border-color: #FFFFFF;
}

body #NavbarVeterinaria .navbar-header .navbar-toggle:hover .icon-bar, body #NavbarVeterinaria .navbar-header .navbar-toggle:active .icon-bar, body #NavbarVeterinaria .navbar-header .navbar-toggle:focus .icon-bar {
  background-color: #FFFFFF;
}

body #NavbarVeterinaria .navbar-header .navbar-toggle .icon-bar {
  background-color: #2984be;
}

body #NavbarVeterinaria #icono-tipo-cliente {
  float: right;
  color: #FFFFFF;
  border-left: solid 2px #FFFFFF;
  position: relative;
  height: 100%;
  padding-left: 15px;
}

body #NavbarVeterinaria #icono-tipo-cliente span {
  float: left;
  font-size: 45px;
}

body #NavbarVeterinaria #icono-tipo-cliente p {
  margin-top: 8px;
  margin-left: 8px;
  float: left;
  font-family: "Lato-Black";
  text-transform: uppercase;
}

body #NavbarVeterinaria .navbar-collapse .in {
  overflow-y: visible;
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav > .open > a, body #NavbarVeterinaria .navbar-collapse .navbar-default .navbar-nav > .open > a:hover, body #NavbarVeterinaria .navbar-collapse .navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent !important;
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav {
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  -webkit-box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
  -moz-box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
  box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav > .open > a:hover, body #NavbarVeterinaria .navbar-collapse .navbar-nav > .open > a:active, body #NavbarVeterinaria .navbar-collapse .navbar-nav > .open > a:focus {
  background-color: transparent !important;
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown-item.active, body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown-item:active {
  background-color: transparent !important;
  color: #2984be !important;
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu {
  border-radius: 0px 0px 5px 5px;
  border: 0px;
  margin-top: -0.5px;
  -webkit-box-shadow: -1px 19px 28px -7px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: -1px 19px 28px -7px rgba(0, 0, 0, 0.22);
  box-shadow: -1px 19px 28px -7px rgba(0, 0, 0, 0.22);
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu a {
  font-size: 12.5px;
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu a:hover, body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu a:focus, body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu a:active {
  background-color: transparent !important;
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav li {
  background-color: #FFFFFF;
  width: 33.333333333333333333333333333333%;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  height: 80px;
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav li a {
  text-decoration: none;
  color: #777;
  position: relative;
  padding-top: 18px;
  width: 100%;
  font-family: "Lato-Regular";
}

body #NavbarVeterinaria .navbar-collapse .navbar-nav li a:hover, body #NavbarVeterinaria .navbar-collapse .navbar-nav li a:active, body #NavbarVeterinaria .navbar-collapse .navbar-nav li a:focus {
  color: #2984be;
}

body #NavbarVeterinaria button:focus {
  outline: none !important;
}

body #NavbarVeterinaria .navbar-toggler {
  padding-top: 30px;
  padding-right: 25px;
  border: none;
  background: transparent !important;
}

body #NavbarVeterinaria .navbar-toggler:hover, body #NavbarVeterinaria .navbar-toggler:active, body #NavbarVeterinaria .navbar-toggler:focus {
  border: none;
  background: transparent !important;
}

body #NavbarVeterinaria .navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #FFFFFF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

body #NavbarVeterinaria .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

body #NavbarVeterinaria .navbar-toggler.x .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  -ms-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

body #NavbarVeterinaria .navbar-toggler.x .icon-bar:nth-of-type(2) {
  opacity: 0;
  filter: alpha(opacity=0);
}

body #NavbarVeterinaria .navbar-toggler.x .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  -ms-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
}

body #NavbarVeterinaria .navbar-toggler.x.collapsed .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

body #NavbarVeterinaria .navbar-toggler.x.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1;
  filter: alpha(opacity=100);
}

body #NavbarVeterinaria .navbar-toggler.x.collapsed .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #NavbarVeterinaria {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  body #NavbarVeterinaria #brand {
    height: 43px;
    left: 10px;
    top: -10px;
  }
  body #NavbarVeterinaria #icono-tipo-cliente {
    position: absolute;
    width: 138.172px;
    float: left;
    left: 20px;
    border-left: none;
    border-top: solid 2px #FFFFFF;
    top: 50px;
    padding-top: 1%;
    padding-left: 2px;
    padding-right: 2px;
    height: 10%;
  }
  body #NavbarVeterinaria #icono-tipo-cliente span {
    float: left;
    font-size: 35px;
  }
  body #NavbarVeterinaria #icono-tipo-cliente p {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 13.5px;
    float: left;
    font-family: "Lato-Black";
    text-transform: uppercase;
  }
  body #NavbarVeterinaria .navbar-header {
    padding: 10px !important;
    height: 44px !important;
    border: none;
  }
  body #NavbarVeterinaria .navbar-collapse {
    position: relative;
    top: -4px;
    border: none;
    box-shadow: none;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav {
    position: relative;
    top: 0px;
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown #dropdown-container #dropdown-link {
    max-width: 100px;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-toggle {
    max-width: 27px;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu {
    text-align: left !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu > a {
    position: relative;
    left: 50% !important;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav li {
    background-color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    height: 100%;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav li a {
    padding-top: 18px;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  body #NavbarVeterinaria {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
  }
  body #NavbarVeterinaria #brand {
    height: 43px;
    left: 10px;
    top: -10px;
  }
  body #NavbarVeterinaria #icono-tipo-cliente {
    position: absolute;
    width: 138.172px;
    float: left;
    left: 20px;
    border-left: none;
    border-top: solid 2px #FFFFFF;
    top: 50px;
    padding-top: 1%;
    padding-left: 2px;
    padding-right: 2px;
    height: 10%;
  }
  body #NavbarVeterinaria #icono-tipo-cliente span {
    float: left;
    font-size: 35px;
  }
  body #NavbarVeterinaria #icono-tipo-cliente p {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 13.5px;
    float: left;
    font-family: "Lato-Black";
    text-transform: uppercase;
  }
  body #NavbarVeterinaria .navbar-header {
    padding: 10px !important;
    height: 44px !important;
    border: none;
  }
  body #NavbarVeterinaria .navbar-collapse {
    position: relative;
    top: -4px;
    border: none;
    box-shadow: none;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav {
    position: relative;
    top: 0px;
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown #dropdown-container #dropdown-link {
    max-width: 100px;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-toggle {
    max-width: 27px;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu {
    text-align: left !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav .dropdown .dropdown-menu > a {
    position: relative;
    left: 35% !important;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav li {
    background-color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    height: 100%;
  }
  body #NavbarVeterinaria .navbar-collapse .navbar-nav li a {
    padding-top: 18px;
    width: 100%;
  }
}
