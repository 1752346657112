/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

.BotonDashboard {
  margin-top: 25px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.42);
}

.BotonDashboard .contenedorIcono {
  background-color: #26c6da;
  color: #FFFFFF;
  border-radius: 5px;
  position: relative;
  top: -20px;
  width: 50%;
  padding-bottom: 10%;
}

.BotonDashboard .contenedorIcono svg {
  font-size: 50px;
  margin-top: 20px;
}

.BotonDashboard span {
  font-family: "Bebas-Neue-Regular";
  text-transform: uppercase;
  font-size: 25px;
}
