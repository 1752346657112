/* Sass Document */
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-promociones {
  position: relative;
  margin-top: 150px;
  display: flex;
  z-index: 3;
}

body #seccion-promociones h1 {
  position: relative;
  top: 10px;
  font-family: "Lato-Black";
  text-align: left;
  padding-left: 10%;
  padding-bottom: 10px;
  border-bottom: solid 5px #2984be;
}

body #seccion-promociones .container {
  padding-top: 25px;
  padding-bottom: 75px;
}

body #seccion-promociones .container .flip-card {
  background-color: transparent;
  width: 100%;
  padding-top: 100%;
  perspective: 1000px;
  position: relative;
  display: block;
  margin-top: 25px;
}

body #seccion-promociones .container .flip-card-inner {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

body #seccion-promociones .container .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

body #seccion-promociones .container .flip-card:active .flip-card-inner {
  transform: rotateY(180deg);
}

body #seccion-promociones .container .flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

body #seccion-promociones .container .flip-card-front, body #seccion-promociones .container .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

body #seccion-promociones .container .flip-card-front {
  background-color: #bbb;
  color: black;
  z-index: 2;
}

body #seccion-promociones .container .flip-card-front img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

body #seccion-promociones .container .flip-card-front .fondo-promocion {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  top: 0px;
  width: 100%;
  height: 100%;
}

body #seccion-promociones .container .flip-card-front .fondo-promocion h3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF;
  font-family: "Lato-Black";
  width: 100%;
  margin: 0px;
}

body #seccion-promociones .container .flip-card-back {
  background-color: #ededed;
  padding-top: 100%;
  transform: rotateY(180deg);
  z-index: 1;
}

body #seccion-promociones .container .flip-card-back .borde-izquierdo-azul {
  position: absolute;
  background-color: #2984be;
  height: 100%;
  top: 0px;
}

body #seccion-promociones .container .flip-card-back .contenidoProm {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: 8.33%;
  padding: 5px;
}

body #seccion-promociones .container .flip-card-back .contenidoProm h3 {
  font-family: "Lato-Black";
  text-transform: uppercase;
  font-size: 24px;
}

body #seccion-promociones .container .flip-card-back .contenidoProm p {
  font-family: "Lato-Regular";
  font-size: 16px;
}
