.mfb-component__button,
.mfb-component__button--main,
.mfb-component__button--child {
  color: #FFFFFF !important;
  background-color: #F8AB00 !important;
}

.mfb-component__button:hover, .mfb-component__button:active, .mfb-component__button:focus,
.mfb-component__button--main:hover,
.mfb-component__button--main:active,
.mfb-component__button--main:focus,
.mfb-component__button--child:hover,
.mfb-component__button--child:active,
.mfb-component__button--child:focus {
  color: #FFFFFF !important;
  background-color: #F8AB00 !important;
}
