/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../fonts/BebasNeue.eot), url(../fonts/BebasNeue.otf), url(../fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../fonts/BebasNeue_Regular.eot), url(../fonts/BebasNeue_Regular.otf), url(../fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../fonts/Lato-Black.eot), url(../fonts/Lato-Black.otf), url(../fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../fonts/Lato-Regular.eot), url(../fonts/Lato-Regular.otf), url(../fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../fonts/GOTHIC.eot), url(../fonts/GOTHIC.otf), url(../fonts/GOTHIC.ttf);
}

body footer {
  height: 100%;
  background-color: #19597F;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

body footer .container-fluid {
  position: relative;
  padding-top: 8px;
  padding-left: 5%;
  padding-right: 5%;
}

body footer #logo-footer-inicio {
  top: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

body footer #logo-footer-inicio img {
  width: 80%;
  margin: auto;
}

body footer #info-footer-inicio div h4 {
  position: relative;
  font-family: "Lato-Black";
  color: #FFFFFF;
  padding-left: 10%;
  font-size: 18px;
}

body footer #info-footer-inicio div h5 {
  font-family: "Lato-Regular";
  color: #FFFFFF;
  font-size: 20px;
}

body footer #info-footer-inicio div p,
body footer #info-footer-inicio div li {
  position: relative;
  font-family: "Lato-Regular";
  color: #FFFFFF;
  padding-left: 10%;
  font-size: 14px;
}

body footer #redes-inicio {
  top: 30px;
}

body footer #redes-inicio h3 {
  font-family: "Lato-Regular";
  color: #FFFFFF;
  font-size: 24px;
}

body footer #redes-inicio #iconos-redes-inicio {
  position: relative;
  left: 9%;
  width: 100%;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons {
  width: 25%;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons a {
  text-decoration: none;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons .icono {
  background-color: #FFFFFF;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  text-decoration: none;
  transition: ease-in-out 0.5s;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons .icono:hover, body footer #redes-inicio #iconos-redes-inicio .container-icons .icono:active, body footer #redes-inicio #iconos-redes-inicio .container-icons .icono:focus {
  background-color: #3dbcb2;
  transition: ease-in-out 0.5s;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons .icono span {
  position: relative;
  text-decoration: none;
  top: 8px;
  font-size: 1.5em;
  color: #19597F;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons #fb span {
  left: 13px;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons #tw span {
  left: 4.6px;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons #ins span {
  left: 7.5px;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons #yt span {
  left: 13.4px;
}

body footer #redes-inicio #iconos-redes-inicio .container-icons #in span {
  left: 9px;
}

body footer #copyright-inicio {
  border-top: solid 2px #FFFFFF;
  padding-top: 10px;
  padding-bottom: 10px;
}

body footer #copyright-inicio #footer-logo-p-lao-inicio {
  width: 10em;
}

body footer #copyright-inicio p {
  top: 15px;
  font-size: 1.2em;
  font-family: "Century-Gothic";
  color: #FFFFFF;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body footer #redes-inicio {
    top: 0px !important;
    padding-bottom: 25px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body footer #logo-footer-inicio {
    top: 0px !important;
    width: 100% !important;
    margin-left: 65px;
    padding-bottom: 15px;
  }
  body footer #redes-inicio {
    top: 0px !important;
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 500px) {
  body footer #logo-footer-inicio {
    top: 0px !important;
    width: 100% !important;
    margin-left: 28px;
    padding-bottom: 15px;
  }
  body footer #redes-inicio {
    top: 0px !important;
    padding-bottom: 25px;
  }
}
