/* Sass Document */
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../Components/fonts/BebasNeue.eot), url(../../../Components/fonts/BebasNeue.otf), url(../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../Components/fonts/BebasNeue_Regular.eot), url(../../../Components/fonts/BebasNeue_Regular.otf), url(../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../Components/fonts/Lato-Black.eot), url(../../../Components/fonts/Lato-Black.otf), url(../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../Components/fonts/Lato-Regular.eot), url(../../../Components/fonts/Lato-Regular.otf), url(../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../Components/fonts/GOTHIC.eot), url(../../../Components/fonts/GOTHIC.otf), url(../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-contacto {
  margin-top: 150px;
  z-index: 3;
}

body #seccion-contacto h1 {
  position: relative;
  top: 10px;
  font-family: "Lato-Black";
  text-align: left;
  padding-left: 10%;
  padding-bottom: 10px;
  border-bottom: solid 5px #2984be;
}

body #seccion-contacto div {
  margin-top: 25px;
  margin-bottom: 25px;
}

body #seccion-contacto div #laboratorios-analisis-clinicos {
  margin-top: 50px;
  height: 100%;
}

body #seccion-contacto div #laboratorios-analisis-clinicos .row h4 {
  font-family: "Lato-Black";
  font-size: 20px;
}

body #seccion-contacto div #laboratorios-analisis-clinicos .row .texto {
  height: 100%;
}

body #seccion-contacto div #laboratorios-analisis-clinicos .row .texto p {
  font-family: "Lato-Regular";
  height: 400px;
}

body #seccion-contacto div #laboratorios-analisis-clinicos .row .mapa {
  height: 100%;
}

body #seccion-contacto div #laboratorios-analisis-clinicos .row .mapa iframe {
  height: 400px;
}

body #seccion-contacto div #laboratorios-de-referencia {
  height: 100%;
}

body #seccion-contacto div #laboratorios-de-referencia .row h4 {
  font-family: "Lato-Black";
  font-size: 20px;
}

body #seccion-contacto div #laboratorios-de-referencia .row .texto {
  height: 100%;
}

body #seccion-contacto div #laboratorios-de-referencia .row .texto p {
  font-family: "Lato-Regular";
  height: 400px;
}

body #seccion-contacto div #laboratorios-de-referencia .row .mapa {
  height: 100%;
}

body #seccion-contacto div #laboratorios-de-referencia .row .mapa iframe {
  height: 400px;
}

body #form-contacto {
  position: relative;
  margin-bottom: 50px;
}

body #form-contacto form {
  float: right;
}

body #form-contacto form input {
  margin-bottom: 10px;
  border: none;
  background-color: #ededed;
  height: 35px;
  font-style: italic;
  font-family: "Lato-Regular";
  transition: ease-in-out 0.5s;
}

body #form-contacto form input:active, body #form-contacto form input:focus, body #form-contacto form input:hover {
  font-style: normal;
  transition: ease-in-out 0.5s;
}

body #form-contacto form textarea {
  margin-bottom: 10px;
  border: none;
  background-color: #ededed;
  height: 120px;
  font-style: italic;
  font-family: "Lato-Regular";
  transition: ease-in-out 0.5s;
}

body #form-contacto form textarea:active, body #form-contacto form textarea:focus, body #form-contacto form textarea:hover {
  font-style: normal;
  transition: ease-in-out 0.5s;
}

body #form-contacto form button {
  height: 35px;
  border: none;
  background-color: #ededed;
  font-style: italic;
  font-family: "Lato-Regular";
  transition: ease-in-out 0.5s;
}

body #form-contacto form button:active, body #form-contacto form button:focus, body #form-contacto form button:hover {
  font-style: normal;
  background-color: #2984be;
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
}

body #form-contacto #red-social-form {
  top: 30px;
}

body #form-contacto #red-social-form h3 {
  font-family: "Lato-Black";
  color: #2984be;
}

body #form-contacto #red-social-form #iconos-redes-inicio {
  position: relative;
  left: 9%;
  width: 100%;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons {
  width: 20%;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons a {
  text-decoration: none;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons .icono {
  background-color: #2984be;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  text-decoration: none;
  transition: ease-in-out 0.5s;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons .icono:hover, body #form-contacto #red-social-form #iconos-redes-inicio .container-icons .icono:active, body #form-contacto #red-social-form #iconos-redes-inicio .container-icons .icono:focus {
  background-color: #3dbcb2;
  transition: ease-in-out 0.5s;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons .icono span {
  position: relative;
  text-decoration: none;
  top: 8px;
  font-size: 1.5em;
  color: #FFFFFF;
  z-index: 1;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons #fb span {
  left: 13px;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons #tw span {
  left: 4.6px;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons #ins span {
  left: 7.5px;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons #yt span {
  left: 13.4px;
}

body #form-contacto #red-social-form #iconos-redes-inicio .container-icons #in span {
  left: 9px;
}

body #form-contacto #mensaje {
  margin-top: 50px;
}

body #form-contacto #mensaje i h3 {
  font-family: "Lato-Black";
  position: relative;
  color: #3dbcb2;
}

body #form-contacto #mensaje i h3 span {
  font-size: 50px;
}

body .sin-margen-y-padding {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #seccion-contacto div #laboratorios-analisis-clinicos .row .texto p {
    font-family: "Lato-Regular";
    height: 100%;
  }
  body #seccion-contacto div #laboratorios-de-referencia .row .texto p {
    font-family: "Lato-Regular";
    height: 100%;
  }
  body #form-contacto {
    position: relative;
    margin-bottom: 50px;
  }
  body #form-contacto form {
    float: right;
  }
  body #form-contacto form input {
    margin-bottom: 10px;
  }
  body #form-contacto form textarea {
    margin-bottom: 10px;
  }
  body #form-contacto #red-social-form {
    position: relative;
    margin-left: 60px;
    top: 10px;
    height: 100%;
  }
  body #form-contacto #red-social-form h3 {
    width: 260px;
  }
  body #form-contacto #red-social-form #iconos-redes-form {
    width: 260px;
  }
}

@media only screen and (max-width: 500px) {
  body #seccion-contacto div #laboratorios-analisis-clinicos .row .texto p {
    font-family: "Lato-Regular";
    height: 100%;
  }
  body #seccion-contacto div #laboratorios-de-referencia .row .texto p {
    font-family: "Lato-Regular";
    height: 100%;
  }
  body #form-contacto {
    position: relative;
    margin-bottom: 50px;
  }
  body #form-contacto form {
    float: right;
  }
  body #form-contacto form input {
    margin-bottom: 10px;
  }
  body #form-contacto form textarea {
    margin-bottom: 10px;
  }
  body #form-contacto #red-social-form {
    position: relative;
    top: 15px;
    height: 100%;
  }
  body #form-contacto #red-social-form h3 {
    width: 200px;
  }
  body #form-contacto #red-social-form #iconos-redes-form {
    width: 200px;
  }
}
