/* Sass Document */
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-iframe-resultados-en-linea {
  margin-top: 150px;
  z-index: 3;
}

body #seccion-iframe-resultados-en-linea h1 {
  position: relative;
  top: 10px;
  font-family: "Lato-Black";
  text-align: left;
  padding-left: 10%;
  padding-bottom: 10px;
  border-bottom: solid 5px #2984be;
}

body #seccion-iframe-resultados-en-linea iframe {
  height: 100vh;
}

body #seccion-iframe-resultados-en-linea form {
  margin-bottom: 50px;
  margin-top: 25px;
}

body #seccion-iframe-resultados-en-linea form div {
  padding-left: 15px;
  padding-right: 15px;
}

body #seccion-iframe-resultados-en-linea form div .input-resultados {
  margin-bottom: 10px;
  border: none;
  background-color: #ededed;
  height: 35px;
  font-style: italic;
  font-family: "Lato-Regular";
  transition: ease-in-out 0.5s;
}

body #seccion-iframe-resultados-en-linea form div .input-resultados:active, body #seccion-iframe-resultados-en-linea form div .input-resultados:focus, body #seccion-iframe-resultados-en-linea form div .input-resultados:hover {
  font-style: normal;
  transition: ease-in-out 0.5s;
}

body #seccion-iframe-resultados-en-linea form div #boton-resultados {
  margin-top: 25px;
  height: 35px;
  border: none;
  background-color: #ededed;
  font-style: italic;
  font-family: "Lato-Regular";
  transition: ease-in-out 0.5s;
}

body #seccion-iframe-resultados-en-linea form div #boton-resultados:active, body #seccion-iframe-resultados-en-linea form div #boton-resultados:focus, body #seccion-iframe-resultados-en-linea form div #boton-resultados:hover {
  font-style: normal;
  background-color: #2984be;
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
}

body .sin-margen-y-padding {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
