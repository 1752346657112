/* Sass Document */
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../Components/fonts/BebasNeue.eot), url(../../../Components/fonts/BebasNeue.otf), url(../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../Components/fonts/BebasNeue_Regular.eot), url(../../../Components/fonts/BebasNeue_Regular.otf), url(../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../Components/fonts/Lato-Black.eot), url(../../../Components/fonts/Lato-Black.otf), url(../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../Components/fonts/Lato-Regular.eot), url(../../../Components/fonts/Lato-Regular.otf), url(../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../Components/fonts/GOTHIC.eot), url(../../../Components/fonts/GOTHIC.otf), url(../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-serviciosmedicos {
  position: relative;
  margin-top: 100px;
  margin-bottom: 50px;
}

body #seccion-serviciosmedicos div #imagen {
  float: right;
  margin-top: 25px;
  margin-bottom: 50px;
}

body #seccion-serviciosmedicos div #texto-servicios-medicos h3 {
  font-family: "Lato-Black";
  text-align: left;
}

body #seccion-serviciosmedicos div #texto-servicios-medicos p {
  font-family: "Lato-Regular";
}

body #seccion-serviciosmedicos div #texto-servicios-medicos #primer-h4 {
  font-family: "Lato-Black";
  color: #2984be;
}

body #seccion-serviciosmedicos div #texto-servicios-medicos #segundo-h4 {
  font-family: "Lato-Black";
  color: #3dbcb2;
}

body .sin-margen-y-padding {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
