/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #contenedor-botones {
  position: relative;
  height: 100%;
}

body #contenedor-botones #titulo-botones {
  text-align: right;
  border-bottom: solid 3px #2984be;
}

body #contenedor-botones #titulo-botones h3 {
  font-size: 22px;
  font-family: "Lato-Black";
  text-transform: uppercase;
}

body #contenedor-botones .btns-inicio {
  color: #2984be;
  height: 100%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3;
  background-color: transparent;
}

body #contenedor-botones .btns-inicio:hover, body #contenedor-botones .btns-inicio:active, body #contenedor-botones .btns-inicio:focus {
  color: #FFFFFF;
}

body #contenedor-botones .btns-inicio:hover > div > svg,
body #contenedor-botones .btns-inicio:active > div > svg,
body #contenedor-botones .btns-inicio:focus > div > svg {
  font-size: 50px !important;
}

body #contenedor-botones .btns-inicio:hover > div > p,
body #contenedor-botones .btns-inicio:active > div > p,
body #contenedor-botones .btns-inicio:focus > div > p {
  color: #FFFFFF;
}

body #contenedor-botones .btns-inicio svg {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

body #contenedor-botones .btns-inicio svg:hover, body #contenedor-botones .btns-inicio svg:active, body #contenedor-botones .btns-inicio svg:focus {
  font-size: 50px !important;
}

body #contenedor-botones .btns-inicio p {
  color: #424141;
}

body #contenedor-botones #btn1-inicio {
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #12597C;
}

body #contenedor-botones #btn1-inicio:hover, body #contenedor-botones #btn1-inicio:active, body #contenedor-botones #btn1-inicio:focus {
  color: #FFFFFF;
  background-color: #12597C;
}

body #contenedor-botones #btn1-inicio svg {
  font-size: 40px;
}

body #contenedor-botones #btn2-inicio {
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #EF5930;
}

body #contenedor-botones #btn2-inicio:hover, body #contenedor-botones #btn2-inicio:active, body #contenedor-botones #btn2-inicio:focus {
  color: #FFFFFF;
  background-color: #EF5930;
}

body #contenedor-botones #btn2-inicio span {
  font-size: 50px;
}

body #contenedor-botones #btn2-inicio svg {
  font-size: 40px;
}

body #contenedor-botones #btn3-inicio {
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #3CB9AA;
}

body #contenedor-botones #btn3-inicio:hover, body #contenedor-botones #btn3-inicio:active, body #contenedor-botones #btn3-inicio:focus {
  color: #FFFFFF;
  background-color: #3CB9AA;
}

body #contenedor-botones #btn3-inicio span {
  font-size: 50px;
}

body #contenedor-botones #btn3-inicio svg {
  font-size: 40px;
}

body #contenedor-botones #btn4-inicio {
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #FBA930;
}

body #contenedor-botones #btn4-inicio:hover, body #contenedor-botones #btn4-inicio:active, body #contenedor-botones #btn4-inicio:focus {
  color: #FFFFFF;
  background-color: #FBA930;
}

body #contenedor-botones #btn4-inicio span {
  font-size: 50px;
}

body #contenedor-botones #btn4-inicio svg {
  font-size: 40px;
}

body #contenedor-botones #btn5-inicio {
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #CE3F42;
}

body #contenedor-botones #btn5-inicio:hover, body #contenedor-botones #btn5-inicio:active, body #contenedor-botones #btn5-inicio:focus {
  color: #FFFFFF;
  background-color: #CE3F42;
}

body #contenedor-botones #btn5-inicio span {
  font-size: 50px;
}

body #contenedor-botones #btn5-inicio svg {
  font-size: 40px;
}

body #contenedor-botones a {
  font-family: "Lato-Regular";
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  border: none;
  height: 20%;
  margin-top: 8px;
  margin-left: 0px;
  background-color: transparent;
  color: #FFFFFF;
  padding-top: 10px;
}

body #contenedor-botones a span {
  color: #FFFFFF;
}

@media only screen and (min-width: 1200px) {
  body #contenedor-botones a {
    width: 20% !important;
    flex-grow: 1;
    top: 0px;
    height: 100px;
    margin-top: 0px;
    font-size: 13px;
  }
  body #contenedor-botones a span {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body #contenedor-botones a {
    width: 20% !important;
    flex-grow: 1;
    top: 0px;
    height: 100px;
    margin-top: 0px;
    font-size: 13px;
  }
  body #contenedor-botones a span {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body #contenedor-botones a {
    width: 20% !important;
    flex-grow: 1;
    top: 0px;
    height: 150px;
    margin-top: 0px;
    font-size: 13px;
  }
  body #contenedor-botones a span {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #contenedor-botones #btn1-inicio span {
    font-size: 25px;
  }
  body #contenedor-botones #btn2-inicio span {
    font-size: 35px;
  }
  body #contenedor-botones #btn3-inicio span {
    font-size: 35px;
  }
  body #contenedor-botones #btn4-inicio span {
    font-size: 35px;
  }
  body #contenedor-botones #btn5-inicio span {
    font-size: 35px;
  }
  body #contenedor-botones #titulo-botones {
    text-align: center;
  }
  body #contenedor-botones a {
    width: 20% !important;
    height: 100px;
    font-size: 10px;
  }
  body #contenedor-botones a span {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 500px) {
  body #contenedor-botones #titulo-botones {
    text-align: center;
  }
  body #contenedor-botones #btn1-inicio {
    color: #FFFFFF;
    background-color: #12597C;
  }
  body #contenedor-botones #btn1-inicio p {
    color: #FFFFFF;
  }
  body #contenedor-botones #btn2-inicio {
    color: #FFFFFF;
    background-color: #EF5930;
  }
  body #contenedor-botones #btn2-inicio p {
    color: #FFFFFF;
  }
  body #contenedor-botones #btn3-inicio {
    color: #FFFFFF;
    background-color: #3CB9AA;
  }
  body #contenedor-botones #btn3-inicio p {
    color: #FFFFFF;
  }
  body #contenedor-botones #btn4-inicio {
    color: #FFFFFF;
    background-color: #FBA930;
  }
  body #contenedor-botones #btn4-inicio p {
    color: #FFFFFF;
  }
  body #contenedor-botones #btn5-inicio {
    color: #FFFFFF;
    background-color: #CE3F42;
  }
  body #contenedor-botones #btn5-inicio p {
    color: #FFFFFF;
  }
  body #contenedor-botones a {
    font-size: 15px;
    width: 100%;
    height: 60%;
    margin: 0px;
  }
}
