/* Sass Document */
/*Quienes Somos Pacientes*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../Components/fonts/BebasNeue.eot), url(../../../Components/fonts/BebasNeue.otf), url(../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../Components/fonts/BebasNeue_Regular.eot), url(../../../Components/fonts/BebasNeue_Regular.otf), url(../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../Components/fonts/Lato-Black.eot), url(../../../Components/fonts/Lato-Black.otf), url(../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../Components/fonts/Lato-Regular.eot), url(../../../Components/fonts/Lato-Regular.otf), url(../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../Components/fonts/GOTHIC.eot), url(../../../Components/fonts/GOTHIC.otf), url(../../../Components/fonts/GOTHIC.ttf);
}

body #anchor-historia {
  position: relative;
  top: -120px;
}

body #seccion-historia {
  margin-top: 50px;
  margin-bottom: 100px;
}

body #seccion-historia #contenedor-sub-historia {
  text-align: right;
  border-bottom: solid 3px #2984be;
}

body #seccion-historia #contenedor-sub-historia h3 {
  font-size: 22px;
  font-family: "Lato-Black";
  text-transform: uppercase;
}

body #seccion-historia #contenedor-texto-historia {
  margin-top: 40px;
}

body #seccion-historia #contenedor-texto-historia p {
  font-family: "Lato-Regular";
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #seccion-historia #contenedor-sub-historia {
    text-align: center;
  }
  body #contenedor-texto-historia {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 40px;
  }
  body #contenedor-texto-historia p {
    font-family: "Lato-Regular";
  }
}

@media only screen and (max-width: 500px) {
  body #seccion-historia #contenedor-sub-historia {
    text-align: center;
  }
  body #contenedor-texto-historia {
    padding-left: 15px;
    padding-right: 15px;
  }
}
