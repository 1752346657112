/* Sass Document */
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #galeria-empresas-e-industria-analisis-fisico-quimico {
  margin-top: 150px;
  z-index: 3;
}

body #galeria-empresas-e-industria-analisis-fisico-quimico h1 {
  position: relative;
  top: 10px;
  font-family: "Lato-Black";
  text-align: left;
  padding-left: 10%;
  padding-bottom: 10px;
  border-bottom: solid 5px #2984be;
  margin-bottom: 10px;
}

body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-indicators .active {
  background-color: #2984be;
}

body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-indicators {
  bottom: -50px;
}

body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-indicators li {
  border: 1px solid #2984be;
  height: 10px !important;
  width: 10px !important;
  border-radius: 50px !important;
}

body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .carousel-item img {
  width: 100%;
}

body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .carousel-item .carousel-caption {
  bottom: 0%;
  left: 0%;
  right: 0%;
  padding-bottom: 0%;
}

body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .carousel-item .carousel-caption h3 {
  font-family: "Lato-Regular";
  text-shadow: none;
  text-align: center;
  opacity: 1;
  padding: 5px;
  margin-bottom: 0%;
}

body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .carousel-item .carousel-caption #sub1-carousel {
  background-color: rgba(121, 168, 211, 0.5);
}

body .sin-margen-y-padding {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

body #seccion-analisis-agua-alimentos {
  margin-top: 100px;
  margin-bottom: 100px;
}

body #seccion-analisis-agua-alimentos .container h4 {
  font-family: "Lato-Black";
  padding-bottom: 20px;
}

body #seccion-analisis-agua-alimentos a {
  text-decoration: none;
  cursor: auto;
}

body #seccion-analisis-agua-alimentos a .examenes {
  color: #000000;
}

body #seccion-analisis-agua-alimentos p {
  font-family: "Lato-Regular";
  text-align: left;
  font-size: 20px;
  padding-left: 12%;
}

body #seccion-analisis-agua-alimentos .contenedor-acreditacion {
  background-color: #3dbcb2;
}

body #seccion-analisis-agua-alimentos .contenedor-acreditacion div {
  padding-top: 15px;
  padding-bottom: 15px;
}

body #seccion-analisis-agua-alimentos .contenedor-acreditacion div span {
  color: #FFFFFF;
  font-size: 5em;
}

body #seccion-analisis-agua-alimentos .contenedor-acreditacion div h4 {
  padding-left: 15px;
  margin-top: 5.5%;
  color: #FFFFFF;
  font-family: "Lato-Black";
  font-size: 18px;
}

body #seccion-analisis-agua-alimentos .contenedor-acreditacion div h5 {
  font-family: "Lato-Regular";
  color: #FFFFFF;
  text-align: center;
  font-size: 1.3em;
  margin-top: 3.5%;
}

body #seccion-analisis-agua-alimentos .contenedor-acreditacion div img {
  background-color: #FFFFFF;
}

body #seccion-mensaje {
  margin-top: 100px;
  margin-bottom: 100px;
}

body #seccion-mensaje .container h4 {
  font-family: "Lato-Black";
  padding-bottom: 20px;
}

body #seccion-mensaje span {
  text-decoration: none;
  cursor: auto;
}

body #seccion-mensaje span .examenes {
  color: #000000;
}

body #seccion-mensaje p {
  font-family: "Lato-Regular";
  text-align: left;
  font-size: 20px;
  padding-left: 12%;
}

body #seccion-mensaje #mensaje {
  margin-top: 50px;
}

body #seccion-mensaje #mensaje span h3 {
  font-family: "Lato-Black";
  position: relative;
  color: #3dbcb2;
}

body #seccion-mensaje #mensaje span h3 span {
  font-size: 50px;
}

body #seccion-mensaje #mensaje span p {
  font-family: "Lato-Regular";
  position: relative;
  color: #3dbcb2;
}

@media only screen and (min-width: 1200px) {
  body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .item .carousel-caption h3 {
    text-shadow: none;
    opacity: 1;
    font-size: 25px;
    padding: 10px;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .item .carousel-caption h3 {
    text-shadow: none;
    opacity: 1;
    font-size: 25px;
    padding: 10px;
    text-align: center;
  }
  body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .item .carousel-caption button {
    border-style: none;
    border-radius: 7px;
    font-family: "Lato-Regular";
    color: #FFFFFF;
    height: 40px;
    width: 120px;
    font-size: 18px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .item .carousel-caption h3 {
    text-shadow: none;
    opacity: 1;
    font-size: 22px;
    padding: 10px;
    text-align: center;
  }
  body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .item .carousel-caption button {
    border-style: none;
    border-radius: 7px;
    font-family: "Lato-Regular";
    color: #FFFFFF;
    height: 37px;
    width: 115px;
    font-size: 15px;
    margin-top: 10px;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion {
    background-color: #3dbcb2;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div span {
    padding-top: 5px;
    color: #FFFFFF;
    font-size: 4.5em;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div h4 {
    padding-left: 15px;
    margin-top: 5.5%;
    color: #FFFFFF;
    font-family: "Lato-Black";
    font-size: 18px;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div h5 {
    font-family: "Lato-Regular";
    color: #FFFFFF;
    text-align: center;
    font-size: 1.3em;
    margin-top: 3.5%;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div img {
    background-color: #FFFFFF;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #galeria-empresas-e-industria-analisis-fisico-quimico {
    margin-top: 100px;
  }
  body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .item .carousel-caption h3 {
    text-shadow: none;
    opacity: 1;
    font-size: 20px;
    padding: 5px;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion {
    background-color: #3dbcb2;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div span {
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    font-size: 5em;
    text-align: center;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div h4 {
    padding-left: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5.5%;
    text-align: center;
    color: #FFFFFF;
    font-family: "Lato-Black";
    font-size: 18px;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div h5 {
    font-family: "Lato-Regular";
    color: #FFFFFF;
    text-align: center;
    font-size: 1.3em;
    margin-top: 3.5%;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div img {
    background-color: #FFFFFF;
    max-width: 150px;
  }
}

@media only screen and (max-width: 500px) {
  body #galeria-empresas-e-industria-analisis-fisico-quimico {
    margin-top: 100px;
  }
  body #galeria-empresas-e-industria-analisis-fisico-quimico .carousel-inner .item .carousel-caption h3 {
    position: relative;
    text-shadow: none;
    opacity: 1;
    font-size: 16px;
    padding: 3px;
  }
  body #seccion-analisis-agua-alimentos .examenes {
    width: 100%;
    padding-left: 15%;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion {
    background-color: #3dbcb2;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div span {
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    font-size: 5em;
    text-align: center;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div h4 {
    padding-left: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5.5%;
    text-align: center;
    color: #FFFFFF;
    font-family: "Lato-Black";
    font-size: 18px;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div h5 {
    font-family: "Lato-Regular";
    color: #FFFFFF;
    text-align: center;
    font-size: 1.3em;
    margin-top: 3.5%;
  }
  body #seccion-analisis-agua-alimentos .contenedor-acreditacion div img {
    background-color: #FFFFFF;
    max-width: 150px;
  }
  body #seccion-mensaje .examenes {
    width: 100%;
    padding-left: 15%;
  }
}
