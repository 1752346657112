/* Sass Document */
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-salud-ocupacional-titulo {
  margin-top: 150px;
  z-index: 3;
}

body #seccion-salud-ocupacional-titulo h1 {
  position: relative;
  top: 10px;
  font-family: "Lato-Black";
  text-align: left;
  padding-left: 10%;
  padding-bottom: 10px;
  border-bottom: solid 5px #2984be;
}

body #seccion-salud-ocupacional {
  position: relative;
  margin-top: 25px;
  margin-bottom: 50px;
}

body #seccion-salud-ocupacional span {
  font-size: 20px;
}

body #seccion-salud-ocupacional div #imagen {
  float: right;
  margin-top: 25px;
}

body #seccion-salud-ocupacional div #texto-salud-ocupacional h3 {
  font-family: "Lato-Black";
  text-align: left;
}

body #seccion-salud-ocupacional div #texto-salud-ocupacional p {
  font-family: "Lato-Regular";
}

body #seccion-salud-ocupacional div #texto-salud-ocupacional #primer-h4 {
  font-family: "Lato-Black";
  color: #2984be;
}

body #seccion-salud-ocupacional div #texto-salud-ocupacional #segundo-h4 {
  font-family: "Lato-Black";
  color: #3dbcb2;
}

body #seccion-salud-ocupacional div #mensaje {
  margin-top: 50px;
}

body #seccion-salud-ocupacional div #mensaje a h3 {
  font-family: "Lato-Black";
  position: relative;
  color: #3dbcb2;
}

body #seccion-salud-ocupacional div #mensaje a h3 span {
  font-size: 50px;
}

body #seccion-salud-ocupacional div #mensaje a p {
  font-family: "Lato-Regular";
  position: relative;
  color: #3dbcb2;
}

body .sin-margen-y-padding {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
