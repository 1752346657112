/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #galeria-pacientes-analisis-clinicos {
  margin-top: 150px;
  z-index: 3;
}

body #galeria-pacientes-analisis-clinicos h1 {
  position: relative;
  top: 10px;
  font-family: "Lato-Black";
  text-align: left;
  padding-left: 10%;
  padding-bottom: 10px;
  border-bottom: solid 5px #2984be;
  margin-bottom: 10px;
}

body #galeria-pacientes-analisis-clinicos .carousel-indicators .active {
  background-color: #2984be;
}

body #galeria-pacientes-analisis-clinicos .carousel-indicators {
  bottom: -50px;
}

body #galeria-pacientes-analisis-clinicos .carousel-indicators li {
  border: 1px solid #2984be;
  height: 10px !important;
  width: 10px !important;
  border-radius: 50px !important;
}

body #galeria-pacientes-analisis-clinicos .carousel-inner .carousel-item img {
  width: 100%;
}

body #galeria-pacientes-analisis-clinicos .carousel-inner .carousel-item .carousel-caption {
  bottom: 0%;
  left: 0%;
  right: 0%;
  padding-bottom: 0%;
}

body #galeria-pacientes-analisis-clinicos .carousel-inner .carousel-item .carousel-caption h3 {
  font-family: "Lato-Regular";
  font-style: italic;
  text-shadow: none;
  text-align: center;
  opacity: 1;
  padding: 5px;
  margin-bottom: 0%;
}

body #galeria-pacientes-analisis-clinicos .carousel-inner .carousel-item .carousel-caption #sub1-carousel {
  background-color: rgba(121, 168, 211, 0.5);
}
