/* Sass Document */
/*Estilos Pacientes Analisis Clinicos*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../Components/fonts/BebasNeue.eot), url(../../../Components/fonts/BebasNeue.otf), url(../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../Components/fonts/BebasNeue_Regular.eot), url(../../../Components/fonts/BebasNeue_Regular.otf), url(../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../Components/fonts/Lato-Black.eot), url(../../../Components/fonts/Lato-Black.otf), url(../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../Components/fonts/Lato-Regular.eot), url(../../../Components/fonts/Lato-Regular.otf), url(../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../Components/fonts/GOTHIC.eot), url(../../../Components/fonts/GOTHIC.otf), url(../../../Components/fonts/GOTHIC.ttf);
}

body .modal {
  padding-left: 0px !important;
}

body .modal form .col-md-4 {
  margin-top: 15px;
}

body .modal .modal-dialog {
  margin: 0%;
  width: 100vw !important;
  max-width: 100vw !important;
}

body .modal .modal-dialog .modal-content {
  border-radius: 0px !important;
}

body .modal .modal-dialog .modal-content .modal-header {
  background-color: #2984be !important;
  height: 85px;
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz {
  position: relative;
  top: 10px;
  left: 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5%;
  opacity: 1 !important;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #FFFFFF !important;
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz > div {
  left: 28%;
  right: 28%;
  width: 100%;
  height: 1px;
  background-color: #2984be;
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz > div:first-child {
  -webkit-webkit-transform: rotate(45deg);
  -moz-webkit-transform: rotate(45deg);
  -ms-webkit-transform: rotate(45deg);
  -o-webkit-transform: rotate(45deg);
  webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

body .modal .modal-dialog .modal-content .modal-header .cerrar-cruz > div:last-child {
  -webkit-webkit-transform: rotate(-45deg);
  -moz-webkit-transform: rotate(-45deg);
  -ms-webkit-transform: rotate(-45deg);
  -o-webkit-transform: rotate(-45deg);
  webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body .modal .modal-dialog .modal-content .modal-header .brand-modal {
  position: relative;
  top: 18px;
  height: 32px;
  margin-left: 10%;
}

body .modal .modal-dialog .modal-content .modal-body .mensaje {
  margin-top: 50px;
}

body .modal .modal-dialog .modal-content .modal-body .mensaje a {
  cursor: pointer !important;
  text-decoration: none;
}

body .modal .modal-dialog .modal-content .modal-body .mensaje a h3 {
  font-family: "Lato-Black";
  position: relative;
  color: #3dbcb2;
  border-bottom: none;
  text-align: center !important;
  margin-bottom: 25px;
}

body .modal .modal-dialog .modal-content .modal-body .mensaje a h3 span {
  font-size: 50px;
}

body .modal .modal-dialog .modal-content .modal-body .mensaje a p {
  font-family: "Lato-Regular";
  position: relative;
  color: #3dbcb2;
}

body .modal .modal-dialog .modal-content .modal-body h3 {
  text-align: left;
  text-transform: uppercase;
  padding-left: 8%;
  font-family: "Lato-Black";
  border-bottom: solid 2px #2984be;
}

body .modal .modal-dialog .modal-content .modal-body p {
  font-family: "Lato-Regular";
  margin-top: 50px;
}

body .modal .modal-dialog .modal-content .modal-body p span {
  top: 0px;
  font-size: inherit;
}

body .modal .modal-dialog .modal-content .modal-body p ul {
  font-family: "Lato-Regular";
}

body .modal .modal-dialog .modal-content .modal-body p ul li {
  font-family: "Lato-Regular";
}

body .modal .modal-dialog .modal-content .modal-body strong {
  font-family: "Lato-Regular";
}

body .modal .modal-dialog .modal-content .modal-body span {
  position: relative;
}

body .modal .modal-dialog .modal-content .modal-body point {
  position: relative;
  top: 3px;
  font-size: 25px;
}

body .modal .modal-dialog .modal-content .modal-body img {
  margin-top: 50px;
}

body .modal .modal-dialog .modal-content .modal-body table {
  border: solid 2px #3dbcb2;
  margin-top: 50px;
  margin-bottom: 50px;
}

body .modal .modal-dialog .modal-content .modal-body table th {
  border: solid 2px #3dbcb2;
  background-color: #3dbcb2;
  color: #FFFFFF;
  font-family: "Lato-Black";
  padding-left: 1%;
}

body .modal .modal-dialog .modal-content .modal-body table td {
  border: solid 1px #3dbcb2;
  font-family: "Lato-Regular";
  color: #5b5a5a;
  padding-left: 1%;
}

body .modal .modal-dialog .modal-content .modal-body table td ul {
  list-style-type: none;
}

body .modal .modal-dialog .modal-content .modal-body table td ul li {
  font-family: "Lato-Regular";
  color: #5b5a5a;
}

body .modal .modal-dialog .modal-content .modal-footer {
  border-top: none;
  text-align: center;
  height: 80px;
}

body .modal .modal-dialog .modal-content .modal-footer button {
  border-radius: 0px;
  border: none;
  text-transform: uppercase;
  font-family: "Lato-Black";
  background-color: #2984be;
  color: #FFFFFF;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .mensaje {
  margin-top: 50px;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .mensaje a {
  cursor: pointer !important;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .mensaje a h3 {
  font-family: "Lato-Black";
  position: relative;
  color: #3dbcb2;
  border-bottom: none;
  text-align: center !important;
  margin-bottom: 25px;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .mensaje a h3 span {
  font-size: 50px;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .mensaje a p {
  font-family: "Lato-Regular";
  position: relative;
  color: #3dbcb2;
}

body .modal-type2 .modal-dialog .modal-content .modal-body div .text-font-Lato-Black {
  font-family: "Lato-Black";
}

body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 {
  margin-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div {
  position: relative;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 {
  position: relative;
  background-color: #e4e4e4;
  height: 550px;
  margin-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 h4 {
  font-family: "Lato-Black";
  margin-top: 15px;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 p {
  font-family: "Lato-Regular";
  margin-top: 15px;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 div {
  margin-left: 5%;
  background-color: #2984be;
  height: 75px;
  width: 65px;
  border-radius: 0px 0px 45% 45%;
}

body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 div span {
  position: relative;
  left: 17%;
  top: 15%;
  text-align: center;
  color: #FFFFFF;
  font-size: 3.3em;
}

@media only screen and (min-width: 1200px) {
  body .modal .modal-dialog .modal-content .modal-header .brand-modal {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body #galeria-nosotros-pacientes .carousel-inner .item .carousel-caption h3 {
    text-shadow: none;
    opacity: 1;
    font-size: 25px;
    padding: 10px;
    text-align: center;
  }
  body #galeria-nosotros-pacientes .carousel-inner .item .carousel-caption button {
    border-style: none;
    border-radius: 7px;
    font-family: "Lato-Regular";
    color: #FFFFFF;
    height: 40px;
    width: 120px;
    font-size: 18px;
    margin-top: 15px;
  }
  body .modal .modal-dialog .modal-content .modal-header .brand-modal {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body .modal .modal-dialog .modal-content .modal-header .brand-modal {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body .modal .modal-dialog .modal-content .modal-body h3 {
    text-align: center;
    padding-left: 0%;
  }
  body .modal .modal-dialog .modal-content .modal-body p {
    margin-top: 0px;
  }
  body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 {
    height: 100%;
    padding-bottom: 15px;
  }
  body .modal-type2 .modal-dialog .modal-content .modal-body h3 {
    text-align: center;
    padding-left: 0%;
    padding-right: 0%;
  }
}

@media only screen and (max-width: 500px) {
  body .modal .modal-dialog .modal-content .modal-body h3 {
    text-align: center;
    padding-left: 0%;
  }
  body .modal .modal-dialog .modal-content .modal-body p {
    margin-top: 0px;
  }
  body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 {
    height: 100%;
    padding-bottom: 15px;
  }
  body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 div {
    padding: 0px;
  }
  body .modal-type2 .modal-dialog .modal-content .modal-body .container-cuadros-modal-type2 div .cuadros-modal-type2 div span {
    left: 17%;
  }
  body .modal-type2 .modal-dialog .modal-content .modal-body h3 {
    text-align: center;
    padding-left: 0%;
    padding-right: 0%;
  }
  body .modal-type2 .modal-dialog .modal-content .modal-body div {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  body .modal-type2 .modal-dialog .modal-content .modal-body div img {
    margin-top: 5%;
  }
}
