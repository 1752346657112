.swiper-container {
  height: calc(100% + 5px);
}

.swiper-button-prev {
  color: #FFFFFF;
}

.swiper-button-next {
  color: #FFFFFF;
}

.swiper-pagination-bullet-active {
  background-color: #2984be !important;
}
