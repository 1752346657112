@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?lcg812');
  src:  url('./fonts/icomoon.eot?lcg812#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?lcg812') format('truetype'),
    url('./fonts/icomoon.woff?lcg812') format('woff'),
    url('./fonts/icomoon.svg?lcg812#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-injection:before {
  content: "\e913";
}
.icon-test-tube-flask-and-drop-of-blood:before {
  content: "\e914";
}
.icon-blood-analysis:before {
  content: "\e910";
}
.icon-restaurant-cutlery:before {
  content: "\e911";
}
.icon-tag:before {
  content: "\e912";
}
.icon-home:before {
  content: "\e90f";
}
.icon-icono-ins:before {
  content: "\e90c";
}
.icon-icono-in:before {
  content: "\e903";
}
.icon-icono-yt:before {
  content: "\e90b";
}
.icon-icono-tw:before {
  content: "\e90d";
}
.icon-icono-fb:before {
  content: "\e90e";
}
.icon-passage-of-time:before {
  content: "\e909";
}
.icon-dna-code:before {
  content: "\e90a";
}
.icon-medical-specialist:before {
  content: "\e908";
}
.icon-animal-paw-print:before {
  content: "\e900";
}
.icon-hospital-buildings:before {
  content: "\e901";
}
.icon-icono-de-familia:before {
  content: "\e902";
}
.icon-icono-de-industria:before {
  content: "\e904";
}
.icon-light-bulb:before {
  content: "\e905";
}
.icon-approve-invoice:before {
  content: "\e906";
}
.icon-ojo:before {
  content: "\e907";
}
