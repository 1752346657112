/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../Components/fonts/BebasNeue.eot), url(../../../../Components/fonts/BebasNeue.otf), url(../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../Components/fonts/Lato-Black.eot), url(../../../../Components/fonts/Lato-Black.otf), url(../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../Components/fonts/Lato-Regular.eot), url(../../../../Components/fonts/Lato-Regular.otf), url(../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../Components/fonts/GOTHIC.eot), url(../../../../Components/fonts/GOTHIC.otf), url(../../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-examenes-clinicos {
  margin-top: 100px;
  margin-bottom: 100px;
}

body #seccion-examenes-clinicos .row h4 {
  font-family: "Lato-Black";
  padding-bottom: 20px;
}

body #seccion-examenes-clinicos span {
  text-decoration: none;
  cursor: pointer;
}

body #seccion-examenes-clinicos span:hover, body #seccion-examenes-clinicos span:active, body #seccion-examenes-clinicos span:focus {
  color: #2984be;
}

body #seccion-examenes-clinicos span .examenes {
  color: #000000;
}

body #seccion-examenes-clinicos span .examenes:hover, body #seccion-examenes-clinicos span .examenes:active, body #seccion-examenes-clinicos span .examenes:focus {
  color: #2984be;
}

body #seccion-examenes-clinicos p {
  font-family: "Lato-Regular";
  text-align: left;
  font-size: 20px;
  padding-left: 12%;
}

body #seccion-examenes-clinicos #mensaje {
  margin-top: 50px;
}

body #seccion-examenes-clinicos #mensaje a {
  text-decoration: none;
}

body #seccion-examenes-clinicos #mensaje a h3 {
  font-family: "Lato-Black";
  position: relative;
  color: #3dbcb2;
}

body #seccion-examenes-clinicos #mensaje a h3 span {
  font-size: 50px;
}

body #seccion-examenes-clinicos #mensaje a p {
  font-family: "Lato-Regular";
  position: relative;
  color: #3dbcb2;
}

@media only screen and (max-width: 500px) {
  body #seccion-examenes-clinicos .examenes {
    width: 100%;
    padding-left: 15%;
  }
}
