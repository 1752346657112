/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../fonts/BebasNeue.eot), url(../fonts/BebasNeue.otf), url(../fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../fonts/BebasNeue_Regular.eot), url(../fonts/BebasNeue_Regular.otf), url(../fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../fonts/Lato-Black.eot), url(../fonts/Lato-Black.otf), url(../fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../fonts/Lato-Regular.eot), url(../fonts/Lato-Regular.otf), url(../fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../fonts/GOTHIC.eot), url(../fonts/GOTHIC.otf), url(../fonts/GOTHIC.ttf);
}

body .sin-margen-y-padding {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

body h1 {
  font-size: 40px;
}

@media only screen and (max-width: 500px) {
  body h1 {
    font-size: 30px;
  }
}
