/* Sass Document */
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../Components/fonts/BebasNeue.eot), url(../../../../Components/fonts/BebasNeue.otf), url(../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../Components/fonts/Lato-Black.eot), url(../../../../Components/fonts/Lato-Black.otf), url(../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../Components/fonts/Lato-Regular.eot), url(../../../../Components/fonts/Lato-Regular.otf), url(../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../Components/fonts/GOTHIC.eot), url(../../../../Components/fonts/GOTHIC.otf), url(../../../../Components/fonts/GOTHIC.ttf);
}

body .sin-margen-y-padding {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

body .navbar-servicios-medicos {
  border: none;
  background-color: #2984be;
  border-radius: 0px 0px 0px 0px;
  height: 100px;
  -webkit-box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
  -moz-box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
  box-shadow: 0px 5px 13px 5px rgba(50, 50, 50, 0.22);
}

body .navbar-servicios-medicos #brand {
  position: relative;
  height: 38px;
  top: 0px;
  left: 10vw;
}

body .navbar-servicios-medicos .navbar-header {
  width: 100%;
  padding: 10px !important;
  height: 100% !important;
}

body .navbar-servicios-medicos .navbar-header .navbar-toggle {
  background-color: #FFFFFF;
  border-color: transparent;
  margin-top: 20px;
}

body .navbar-servicios-medicos .navbar-header .navbar-toggle:hover, body .navbar-servicios-medicos .navbar-header .navbar-toggle:active, body .navbar-servicios-medicos .navbar-header .navbar-toggle:focus {
  background-color: #2984be;
  border-color: #FFFFFF;
}

body .navbar-servicios-medicos .navbar-header .navbar-toggle:hover .icon-bar, body .navbar-servicios-medicos .navbar-header .navbar-toggle:active .icon-bar, body .navbar-servicios-medicos .navbar-header .navbar-toggle:focus .icon-bar {
  background-color: #FFFFFF;
}

body .navbar-servicios-medicos .navbar-header .navbar-toggle .icon-bar {
  background-color: #2984be;
}

body .navbar-servicios-medicos #icono-inicio {
  float: right;
  position: relative;
  left: -50px;
}

body .navbar-servicios-medicos #icono-inicio a {
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
}

body .navbar-servicios-medicos #icono-inicio a:hover, body .navbar-servicios-medicos #icono-inicio a:active, body .navbar-servicios-medicos #icono-inicio a:focus {
  color: #3dbcb2;
  transition: ease-in-out 0.5s;
  text-decoration: none;
}

body .navbar-servicios-medicos #icono-inicio a span {
  font-size: 43px;
}

body .navbar-servicios-medicos #icono-inicio a p {
  font-family: "Lato-Black";
}

body .navbar-servicios-medicos #icono-tipo-cliente {
  float: right;
  color: #FFFFFF;
  border-left: solid 2px #FFFFFF;
  position: relative;
  top: 5px;
  height: 100%;
}

body .navbar-servicios-medicos #icono-tipo-cliente span {
  position: relative;
  top: 10px;
  float: left;
  font-size: 45px;
  margin-left: 5px;
}

body .navbar-servicios-medicos #icono-tipo-cliente p {
  position: relative;
  top: 10px;
  margin-top: 0px;
  margin-left: 8px;
  float: left;
  font-family: "Lato-Black";
  text-transform: uppercase;
  font-size: 15px;
}

body #servicios-medicos {
  margin-bottom: 25px;
}

body #servicios-medicos .container .row #servicio-medico-izquierda {
  margin-bottom: 10px;
}

body #servicios-medicos .container .row #servicio-medico-derecha {
  margin-bottom: 10px;
}

body #servicios-medicos .container .row div a {
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  display: block;
  overflow: auto;
}

body #servicios-medicos .container .row div a .container-textos {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  transition: all 0.2s ease-in-out;
}

body #servicios-medicos .container .row div a .container-textos h3 {
  margin: 0px;
  padding: 0px;
}

body #servicios-medicos .container .row div a .container-textos:hover, body #servicios-medicos .container .row div a .container-textos:active, body #servicios-medicos .container .row div a .container-textos:focus {
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

body #servicios-medicos .container .row div a div {
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

body #servicios-medicos #texto-proximamente {
  font-family: "Lato-Black";
  color: #2984be;
  font-size: 50px;
}

body #servicios-medicos a {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  margin-top: 15px;
}

body #servicios-medicos a img {
  position: relative;
  z-index: 1;
  margin: 0px;
}

body #servicios-medicos a div {
  position: absolute;
  height: 100%;
  z-index: 2;
  margin: 0px;
  left: 0px;
  top: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

body #servicios-medicos a div div {
  position: relative;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  margin: 0px;
  left: 0px;
  top: 0px;
  transition: ease-in-out 0.5s;
}

body #servicios-medicos a div h3 {
  left: 0px;
  padding-top: 25%;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  width: 100%;
  text-align: center !important;
  position: absolute;
  z-index: 3;
  color: #FFFFFF;
  margin: 0px;
  font-family: "Lato-Black";
}

body #servicios-medicos a:hover div div,
body #servicios-medicos a:active div div,
body #servicios-medicos a:focus div div {
  background-color: rgba(0, 0, 0, 0.3);
  transition: ease-in-out 0.5s;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body .navbar-servicios-medicos {
    border: none;
  }
  body .navbar-servicios-medicos #brand {
    height: 39px;
    left: 10px;
    top: -15px;
  }
  body .navbar-servicios-medicos #icono-inicio {
    float: right;
    position: relative;
    left: -20px;
  }
  body .navbar-servicios-medicos #icono-inicio a span {
    color: #FFFFFF;
    font-size: 43px;
  }
  body .navbar-servicios-medicos #icono-inicio a p {
    color: #FFFFFF;
    font-family: "Lato-Black";
  }
  body .navbar-servicios-medicos #icono-tipo-cliente {
    position: absolute;
    width: 138.172px;
    float: left;
    left: 20px;
    border-left: none;
    border-top: solid 2px #FFFFFF;
    top: 50px;
    padding-top: 1%;
    padding-left: 2px;
    padding-right: 2px;
    height: 10%;
  }
  body .navbar-servicios-medicos #icono-tipo-cliente span {
    top: 0px;
    float: left;
    font-size: 35px;
  }
  body .navbar-servicios-medicos #icono-tipo-cliente p {
    top: 0px;
    margin-top: -3px;
    margin-left: 8px;
    font-size: 11.5px;
    float: left;
    font-family: "Lato-Black";
    text-transform: uppercase;
  }
  body .navbar-servicios-medicos .navbar-header {
    width: 100%;
    padding: 10px !important;
    height: 100px !important;
    border: none;
  }
  body .navbar-servicios-medicos button .collapsed {
    border: none;
    box-shadow: none;
  }
  body .navbar-servicios-medicos button .navbar-toggle {
    border: none;
    box-shadow: none;
  }
  body .navbar-servicios-medicos .navbar-collapse.in {
    overflow-y: scroll;
  }
  body .navbar-servicios-medicos .navbar-collapse {
    border: none;
    box-shadow: none;
  }
  body .navbar-servicios-medicos .navbar-collapse .navbar-nav {
    position: relative;
    top: 0px;
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  body .navbar-servicios-medicos .navbar-collapse .navbar-nav .dropdown .dropdown-menu > li {
    text-align: left !important;
  }
  body .navbar-servicios-medicos .navbar-collapse .navbar-nav .dropdown .dropdown-menu > li > a {
    position: relative;
    left: 50% !important;
    display: inline !important;
  }
  body .navbar-servicios-medicos .navbar-collapse .navbar-nav li {
    background-color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    height: 100%;
  }
  body .navbar-servicios-medicos .navbar-collapse .navbar-nav li a {
    padding-top: 18px;
    width: 100%;
  }
  body #servicios-medicos .container .row div #servicio-medico-izquierda {
    padding-right: 0px;
    padding-left: 0px;
  }
  body #servicios-medicos .container .row div #servicio-medico-derecha {
    padding-left: 0px;
    padding-right: 0px;
  }
  body #servicios-medicos .container .row div div a {
    padding-left: 0px;
    padding-right: 0px;
  }
  body #servicios-medicos .container .row div div a div {
    padding-left: 0px;
    padding-right: 0px;
  }
  body #servicios-medicos #texto-proximamente {
    font-family: "Lato-Black";
    color: #2984be;
    font-size: 35px;
  }
}

@media only screen and (max-width: 500px) {
  body .navbar-servicios-medicos {
    border: none;
  }
  body .navbar-servicios-medicos #brand {
    height: 39px;
    left: 10px;
    top: -15px;
  }
  body .navbar-servicios-medicos #icono-inicio {
    float: right;
    position: relative;
    left: -20px;
  }
  body .navbar-servicios-medicos #icono-inicio a span {
    color: #FFFFFF;
    font-size: 43px;
  }
  body .navbar-servicios-medicos #icono-inicio a p {
    color: #FFFFFF;
    font-family: "Lato-Black";
  }
  body .navbar-servicios-medicos #icono-tipo-cliente {
    position: absolute;
    width: 138.172px;
    float: left;
    left: 20px;
    border-left: none;
    border-top: solid 2px #FFFFFF;
    top: 50px;
    padding-top: 1%;
    padding-left: 2px;
    padding-right: 2px;
    height: 10%;
  }
  body .navbar-servicios-medicos #icono-tipo-cliente span {
    top: 0px;
    float: left;
    font-size: 35px;
  }
  body .navbar-servicios-medicos #icono-tipo-cliente p {
    top: 0px;
    margin-top: 0px;
    margin-left: 8px;
    font-size: 11.5px;
    float: left;
    font-family: "Lato-Black";
    text-transform: uppercase;
  }
  body .navbar-servicios-medicos .navbar-header {
    width: 100%;
    padding: 10px !important;
    height: 100px !important;
    border: none;
  }
  body .navbar-servicios-medicos button .collapsed {
    border: none;
    box-shadow: none;
  }
  body .navbar-servicios-medicos button .navbar-toggle {
    border: none;
    box-shadow: none;
  }
  body #servicios-medicos .container .row div #servicio-medico-izquierda {
    padding-right: 0px;
    padding-left: 0px;
  }
  body #servicios-medicos .container .row div #servicio-medico-derecha {
    padding-left: 0px;
    padding-right: 0px;
  }
  body #servicios-medicos .container .row div div a {
    padding-left: 0px;
    padding-right: 0px;
  }
  body #servicios-medicos .container .row div div a div {
    padding-left: 0px;
    padding-right: 0px;
  }
  body #servicios-medicos #texto-proximamente {
    font-family: "Lato-Black";
    color: #2984be;
    font-size: 25px;
  }
}
