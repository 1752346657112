/* Sass Document */
/*Quienes Somos Pacientes*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../Components/fonts/BebasNeue.eot), url(../../../../Components/fonts/BebasNeue.otf), url(../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../Components/fonts/Lato-Black.eot), url(../../../../Components/fonts/Lato-Black.otf), url(../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../Components/fonts/Lato-Regular.eot), url(../../../../Components/fonts/Lato-Regular.otf), url(../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../Components/fonts/GOTHIC.eot), url(../../../../Components/fonts/GOTHIC.otf), url(../../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-mision-vision-valores {
  height: 450px;
  margin-top: 50px;
  margin-bottom: 100px;
}

body #seccion-mision-vision-valores #contenedor-mision {
  background-color: #8f88ba;
}

body #seccion-mision-vision-valores #contenedor-mision div {
  background-color: #8f88ba;
}

body #seccion-mision-vision-valores #contenedor-mision #anchor-mision {
  position: relative;
  top: -200px;
}

body #seccion-mision-vision-valores #contenedor-vision {
  background-color: #2984be;
}

body #seccion-mision-vision-valores #contenedor-vision div {
  background-color: #2984be;
}

body #seccion-mision-vision-valores #contenedor-vision #anchor-vision {
  position: relative;
  top: -200px;
}

body #seccion-mision-vision-valores #contenedor-politica {
  background-color: #3dbcb2;
}

body #seccion-mision-vision-valores #contenedor-politica div {
  background-color: #3dbcb2;
}

body #seccion-mision-vision-valores #contenedor-politica #anchor-politica {
  position: relative;
  top: -200px;
}

body #seccion-mision-vision-valores div {
  color: #FFFFFF;
}

body #seccion-mision-vision-valores div h3 {
  font-family: "Lato-Black";
  text-align: center;
}

body #seccion-mision-vision-valores div p {
  font-family: "Lato-Regular";
  font-size: 14px;
}

@media only screen and (min-width: 1200px) {
  body #seccion-mision-vision-valores .contenedor-val-mis-pol {
    height: 100%;
    padding: 50px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .row {
    top: -115px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .row h3 {
    top: -115px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .row p {
    top: -115px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .iconos-mis-vis-pol {
    top: -95px;
    width: 100px;
    height: 100px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .iconos-mis-vis-pol span {
    position: relative;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-mision {
    border-radius: 50%;
    position: relative;
    background-color: #8f88ba;
    left: 30%;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-mision span {
    top: 10px;
    left: 32px;
    font-size: 35px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-vision {
    border-radius: 50%;
    position: relative;
    left: 0px;
    background-color: #2984be;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-vision span {
    top: 15px;
    left: 32px;
    font-size: 22px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-politica {
    border-radius: 50%;
    position: relative;
    right: 30%;
    background-color: #3dbcb2;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-politica span {
    top: 10px;
    left: 32px;
    font-size: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body #seccion-mision-vision-valores {
    height: 560px;
    margin-top: 50px;
    margin-bottom: 200px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol {
    height: 100%;
    padding: 50px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .row {
    top: -115px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .row h3 {
    top: -115px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .row p {
    top: -115px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .iconos-mis-vis-pol {
    border-radius: 50%;
    top: -95px;
    width: 100px;
    height: 100px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .iconos-mis-vis-pol span {
    position: relative;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-mision {
    position: relative;
    background-color: #8f88ba;
    left: 30%;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-mision span {
    top: 10px;
    left: 32px;
    font-size: 35px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-vision {
    position: relative;
    left: 0px;
    background-color: #2984be;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-vision span {
    top: 15px;
    left: 32px;
    font-size: 22px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-politica {
    position: relative;
    background-color: #3dbcb2;
    right: 30%;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol #icono-politica span {
    top: 10px;
    left: 32px;
    font-size: 35px;
  }
  body #seccion-mision-vision-valores #contenedor-mision {
    background-color: #8f88ba;
  }
  body #seccion-mision-vision-valores #contenedor-vision {
    background-color: #2984be;
  }
  body #seccion-mision-vision-valores #contenedor-politica {
    background-color: #3dbcb2;
    margin-bottom: 50px;
  }
  body #seccion-mision-vision-valores div {
    color: #FFFFFF;
  }
  body #seccion-mision-vision-valores div h3 {
    font-family: "Lato-Black";
  }
  body #seccion-mision-vision-valores div p {
    font-family: "Lato-Regular";
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body #seccion-mision-vision-valores {
    height: 350px;
    margin-top: 50px;
    margin-bottom: 400px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol {
    height: 100%;
    padding: 50px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .iconos-mis-vis-pol span {
    position: relative;
    left: 0px;
    font-size: 25px;
  }
  body #seccion-mision-vision-valores #contenedor-mision {
    background-color: #8f88ba;
  }
  body #seccion-mision-vision-valores #contenedor-mision span {
    font-size: 35px;
    left: 0px;
  }
  body #seccion-mision-vision-valores #contenedor-mision #anchor-mision {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores #contenedor-vision {
    background-color: #2984be;
  }
  body #seccion-mision-vision-valores #contenedor-vision span {
    font-size: 22px;
    left: 0px;
  }
  body #seccion-mision-vision-valores #contenedor-vision #anchor-vision {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores #contenedor-politica {
    background-color: #3dbcb2;
    margin-bottom: 50px;
  }
  body #seccion-mision-vision-valores #contenedor-politica span {
    font-size: 35px;
  }
  body #seccion-mision-vision-valores #contenedor-politica #anchor-politica {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores div {
    color: #FFFFFF;
  }
  body #seccion-mision-vision-valores div h3 {
    font-family: "Lato-Black";
  }
  body #seccion-mision-vision-valores div p {
    font-family: "Lato-Regular";
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #seccion-mision-vision-valores {
    height: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol {
    height: 100%;
    padding: 50px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .iconos-mis-vis-pol span {
    position: relative;
    left: 20px;
    font-size: 25px;
  }
  body #seccion-mision-vision-valores #contenedor-mision {
    background-color: #8f88ba;
  }
  body #seccion-mision-vision-valores #contenedor-mision span {
    font-size: 35px;
  }
  body #seccion-mision-vision-valores #contenedor-mision #anchor-mision {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores #contenedor-vision {
    background-color: #2984be;
  }
  body #seccion-mision-vision-valores #contenedor-vision span {
    font-size: 22px;
  }
  body #seccion-mision-vision-valores #contenedor-vision #anchor-vision {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores #contenedor-politica {
    background-color: #3dbcb2;
    margin-bottom: 50px;
  }
  body #seccion-mision-vision-valores #contenedor-politica span {
    font-size: 35px;
  }
  body #seccion-mision-vision-valores #contenedor-politica #anchor-politica {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores div {
    color: #FFFFFF;
  }
  body #seccion-mision-vision-valores div h3 {
    font-family: "Lato-Black";
  }
  body #seccion-mision-vision-valores div p {
    font-family: "Lato-Regular";
  }
}

@media only screen and (max-width: 500px) {
  body #seccion-mision-vision-valores {
    height: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol {
    height: 100%;
    padding: 50px;
  }
  body #seccion-mision-vision-valores .contenedor-val-mis-pol .iconos-mis-vis-pol span {
    position: relative;
    left: 20px;
    font-size: 25px;
  }
  body #seccion-mision-vision-valores #contenedor-mision {
    background-color: #8f88ba;
  }
  body #seccion-mision-vision-valores #contenedor-mision span {
    font-size: 35px;
  }
  body #seccion-mision-vision-valores #contenedor-mision #anchor-mision {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores #contenedor-vision {
    background-color: #2984be;
  }
  body #seccion-mision-vision-valores #contenedor-vision span {
    font-size: 22px;
  }
  body #seccion-mision-vision-valores #contenedor-vision #anchor-vision {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores #contenedor-politica {
    background-color: #3dbcb2;
    margin-bottom: 50px;
  }
  body #seccion-mision-vision-valores #contenedor-politica span {
    font-size: 35px;
  }
  body #seccion-mision-vision-valores #contenedor-politica #anchor-politica {
    position: relative;
    top: -130px;
  }
  body #seccion-mision-vision-valores div {
    color: #FFFFFF;
  }
  body #seccion-mision-vision-valores div h3 {
    font-family: "Lato-Black";
  }
  body #seccion-mision-vision-valores div p {
    font-family: "Lato-Regular";
  }
}
