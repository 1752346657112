/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../Components/fonts/BebasNeue.eot), url(../../../Components/fonts/BebasNeue.otf), url(../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../Components/fonts/BebasNeue_Regular.eot), url(../../../Components/fonts/BebasNeue_Regular.otf), url(../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../Components/fonts/Lato-Black.eot), url(../../../Components/fonts/Lato-Black.otf), url(../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../Components/fonts/Lato-Regular.eot), url(../../../Components/fonts/Lato-Regular.otf), url(../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../Components/fonts/GOTHIC.eot), url(../../../Components/fonts/GOTHIC.otf), url(../../../Components/fonts/GOTHIC.ttf);
}

body #seccion-miembros-inicio {
  margin-bottom: 100px;
}

body #seccion-miembros-inicio #contenedor-sub-miembros-inicio {
  text-align: right;
  border-bottom: solid 3px #2984be;
}

body #seccion-miembros-inicio #contenedor-sub-miembros-inicio h3 {
  font-size: 22px;
  font-family: "Lato-Black";
  text-transform: uppercase;
}

body #seccion-miembros-inicio .slider-miembros div div {
  padding: 25px;
}

body #seccion-miembros-inicio .slider-miembros div div .ratio1-1:before {
  padding-top: 100%;
}

body #seccion-miembros-inicio .slider-miembros div div .contenedor-de-texto-reconocimiento {
  width: 100%;
  position: relative;
}

body #seccion-miembros-inicio .slider-miembros div div .contenedor-de-texto-reconocimiento:before {
  content: '';
  display: block;
  padding-top: 100%;
}

body #seccion-miembros-inicio .slider-miembros div div .contenedor-de-texto-reconocimiento span {
  border-radius: 50%;
  border: solid 3px #2984be;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

body #seccion-miembros-inicio .slider-miembros div div .contenedor-de-texto-reconocimiento span p {
  margin-top: 5%;
  font-size: 16px;
  font-family: "Lato-Black";
  padding: 10%;
  text-align: center;
  vertical-align: middle;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #seccion-miembros-inicio {
    margin-top: 100px;
  }
  body #seccion-miembros-inicio #contenedor-sub-miembros-inicio {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  body #seccion-miembros-inicio {
    margin-top: 100px;
    margin-bottom: 5px;
  }
  body #seccion-miembros-inicio #contenedor-sub-miembros-inicio {
    text-align: center;
  }
  body #seccion-miembros-inicio .slider-container .controller {
    display: none;
  }
}
