/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../Components/fonts/BebasNeue.eot), url(../../../../Components/fonts/BebasNeue.otf), url(../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../Components/fonts/Lato-Black.eot), url(../../../../Components/fonts/Lato-Black.otf), url(../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../Components/fonts/Lato-Regular.eot), url(../../../../Components/fonts/Lato-Regular.otf), url(../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../Components/fonts/GOTHIC.eot), url(../../../../Components/fonts/GOTHIC.otf), url(../../../../Components/fonts/GOTHIC.ttf);
}

body #portada-referencia-analisis-clinicos {
  margin-top: 145px;
  z-index: 3;
  background-color: #E8E8E8;
}

body #portada-referencia-analisis-clinicos h1 {
  font-family: 'Raleway';
  text-transform: uppercase;
  font-weight: 900;
  color: #12597C;
}

body #portada-referencia-analisis-clinicos .info-tab {
  background-color: #FFFFFF;
  min-height: '30px';
  border-radius: 200px 0px 0px 200px;
}

body #portada-referencia-analisis-clinicos .info-tab .icon {
  width: 80px;
  height: 80px;
  border-radius: 100px;
}

body #portada-referencia-analisis-clinicos .info-tab #servicio-icon {
  background-color: #F3A600;
}

body #portada-referencia-analisis-clinicos .info-tab #acreditaciones-icon {
  background-color: #2A929A;
}

body #portada-referencia-analisis-clinicos .info-tab #asesoria-icon {
  background-color: #EE6B42;
}

body #portada-referencia-analisis-clinicos .info-tab #message-icon {
  background-color: #21B9CE;
}

body #portada-referencia-analisis-clinicos .info-tab #message-icon img {
  position: relative;
  top: 8px;
}

body #portada-referencia-analisis-clinicos .info-tab #muestra-icon {
  background-color: #3CB7A8;
}

body #portada-referencia-analisis-clinicos .info-tab #resultados-en-linea-icon {
  background-color: #5E569D;
}

body #portada-referencia-analisis-clinicos .info-tab h3 {
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #2A929A;
}

body #portada-referencia-analisis-clinicos .info-tab p {
  font-family: 'Raleway';
  font-color: #000000;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #portada-referencia-analisis-clinicos .info-tab {
    border-radius: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 500px) {
  body #portada-referencia-analisis-clinicos .info-tab {
    border-radius: 0px 0px 0px 0px;
  }
}
