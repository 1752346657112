/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../Components/fonts/BebasNeue.eot), url(../../../Components/fonts/BebasNeue.otf), url(../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../Components/fonts/BebasNeue_Regular.eot), url(../../../Components/fonts/BebasNeue_Regular.otf), url(../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../Components/fonts/Lato-Black.eot), url(../../../Components/fonts/Lato-Black.otf), url(../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../Components/fonts/Lato-Regular.eot), url(../../../Components/fonts/Lato-Regular.otf), url(../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../Components/fonts/GOTHIC.eot), url(../../../Components/fonts/GOTHIC.otf), url(../../../Components/fonts/GOTHIC.ttf);
}

@font-face {
  font-family: "Times-New-Roman";
  src: url(../fonts/TimesNewRoman/TimesNewRoman.eot), url(../fonts/TimesNewRoman/TimesNewRoman.otf), url(../fonts/TimesNewRoman/TimesNewRoman.ttf);
}

body #seccion-reconocimiento-inicio {
  margin-bottom: 100px;
}

body #seccion-reconocimiento-inicio #contenedor-sub-reconocimiento-inicio {
  text-align: right;
  border-bottom: solid 3px #2984be;
}

body #seccion-reconocimiento-inicio #contenedor-sub-reconocimiento-inicio h3 {
  font-size: 22px;
  font-family: "Lato-Black";
  text-transform: uppercase;
}

body #seccion-reconocimiento-inicio .slider-container {
  z-index: 1;
}

body #seccion-reconocimiento-inicio .slider-container .controller {
  position: relative;
  top: 100px;
  z-index: 2;
}

body #seccion-reconocimiento-inicio .slider-container .controller button {
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  transition: ease-in-out 0.5s;
}

body #seccion-reconocimiento-inicio .slider-container .controller button:active, body #seccion-reconocimiento-inicio .slider-container .controller button:hover, body #seccion-reconocimiento-inicio .slider-container .controller button:focus {
  background-color: transparent;
  border: none;
  outline: none;
}

body #seccion-reconocimiento-inicio .slider-container .controller button:active span,
body #seccion-reconocimiento-inicio .slider-container .controller button:hover span,
body #seccion-reconocimiento-inicio .slider-container .controller button:focus span {
  color: #3dbcb2;
  transition: ease-in-out 0.5s;
}

body #seccion-reconocimiento-inicio .slider-container .controller button span {
  color: #2984be;
  font-size: 35px;
}

body #seccion-reconocimiento-inicio .slider-container .controller .prev-reconocimiento {
  positon: absolute;
  top: 50%;
  float: left;
  left: -15px;
}

body #seccion-reconocimiento-inicio .slider-container .controller .next-reconocimiento {
  float: right;
  top: 50%;
  right: -16px;
}

body #seccion-reconocimiento-inicio .slider-container .slider-reconocimiento div div {
  padding: 5px;
}

body #seccion-reconocimiento-inicio .slider-container .slider-reconocimiento div div .contenedor-de-texto-reconocimiento {
  width: 100%;
  position: relative;
  height: 100%;
}

body #seccion-reconocimiento-inicio .slider-container .slider-reconocimiento div div .contenedor-de-texto-reconocimiento div {
  border-radius: 30px;
  border-right: solid 3px #2984be;
  border-left: solid 3px #2984be;
}

body #seccion-reconocimiento-inicio .slider-container .slider-reconocimiento div div .contenedor-de-texto-reconocimiento div p {
  font-size: 100%;
  font-weight: bolder;
  font-family: "Times-New-Roman";
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0px;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #seccion-reconocimiento-inicio {
    margin-top: 100px;
  }
  body #seccion-reconocimiento-inicio #contenedor-sub-reconocimiento-inicio {
    text-align: center;
  }
  body #seccion-reconocimiento-inicio .slider-container .slider-reconocimiento div div .contenedor-de-texto-reconocimiento span p {
    font-size: 75%;
  }
}

@media only screen and (max-width: 500px) {
  body #seccion-reconocimiento-inicio {
    margin-top: 100px;
    margin-bottom: 5px;
  }
  body #seccion-reconocimiento-inicio #contenedor-sub-reconocimiento-inicio {
    text-align: center;
  }
  body #seccion-reconocimiento-inicio .slider-container .controller {
    display: none;
  }
}
