/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #inicio-portada {
  margin-top: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body #inicio-portada {
    margin-bottom: 45px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #inicio-portada {
    margin-top: 80px;
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 500px) {
  body #inicio-portada {
    margin-top: 80px;
    margin-bottom: 45px;
  }
}
