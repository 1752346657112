/* Sass Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Bebas-Neue-Bold";
  src: url(../../../../../Components/fonts/BebasNeue.eot), url(../../../../../Components/fonts/BebasNeue.otf), url(../../../../../Components/fonts/BebasNeue.ttf);
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(../../../../../Components/fonts/BebasNeue_Regular.eot), url(../../../../../Components/fonts/BebasNeue_Regular.otf), url(../../../../../Components/fonts/BebasNeue_Regular.ttf);
}

@font-face {
  font-family: "Lato-Black";
  src: url(../../../../../Components/fonts/Lato-Black.eot), url(../../../../../Components/fonts/Lato-Black.otf), url(../../../../../Components/fonts/Lato-Black.ttf);
}

@font-face {
  font-family: "Lato-Regular";
  src: url(../../../../../Components/fonts/Lato-Regular.eot), url(../../../../../Components/fonts/Lato-Regular.otf), url(../../../../../Components/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Century-Gothic";
  src: url(../../../../../Components/fonts/GOTHIC.eot), url(../../../../../Components/fonts/GOTHIC.otf), url(../../../../../Components/fonts/GOTHIC.ttf);
}

body #contenedor-img-inicio {
  position: relative;
  min-height: 100px;
}

body #contenedor-img-inicio img {
  display: block;
}

body #contenedor-img-inicio h2 {
  position: relative;
  font-family: "Bebas-Neue-Regular";
  color: #FFFFFF;
  background-color: rgba(37, 118, 167, 0.7);
  text-transform: uppercase;
  text-align: center;
  font-size: 48px;
  left: -15%;
  top: -58px;
  mix-blend-mode: normal;
  padding: 5px;
}

@media only screen and (min-width: 1200px) {
  body #contenedor-img-inicio h2 {
    left: 0px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body #contenedor-img-inicio h2 {
    left: 0px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body #contenedor-img-inicio {
    margin-top: 0px;
    padding-right: 0px;
  }
  body #contenedor-img-inicio h2 {
    left: 0px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  body #contenedor-img-inicio {
    min-height: 100px;
    margin-top: 0px;
    padding-right: 0px;
  }
  body #contenedor-img-inicio h2 {
    left: 0px;
    font-size: 40px;
  }
}

@media only screen and (max-width: 500px) {
  body #contenedor-img-inicio {
    min-height: 100px;
    margin-top: 0px;
    padding-right: 0px;
  }
  body #contenedor-img-inicio h2 {
    left: 0px;
    font-size: 30px;
    top: -85px;
  }
}
